import React, { useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { FaPowerOff } from 'react-icons/fa';
import packageJson from '../../../package.json';
import logo from '../../assets/SGRLock_icon.png';
import logoSwint from '../../assets/swintLogo.svg';
import { useAuth } from '../../contexts/authContext';
import { useSteps } from '../../contexts/stepContext';
import Steps from '../Steps';
import {
  Container,
  ContentImgs,
  ContentSection,
  Header,
  MobileImgs,
  UserData,
  Version,
} from './styles';

const BodyStep: React.FC = ({ children }) => {
  const { companyName, signOut, userName, apiVersion } = useAuth();
  const { currentStep, currentValidation, clearData } = useSteps();

  const handleSignOut = useCallback(() => {
    clearData();
    signOut();
    window.location.href =
      `${process.env.REACT_APP_URL}/operacional/historicocpf` ||
      'https://swint.imobpower.com/operacional/historicocpf';
  }, [clearData, signOut]);

  return (
    <Container
      firstStep={currentStep === 1 && isMobileOnly}
      nextSteps={currentStep > 1 && isMobileOnly}
    >
      {isMobileOnly ? (
        <>
          {currentStep !== 1 ? (
            <>
              <Header>
                <h1>Identificação por {currentValidation}</h1>
                <Steps />
              </Header>
              <ContentSection>{children}</ContentSection>
            </>
          ) : (
            <>
              <MobileImgs>
                <img src={logo} alt="" />
                <img src={logoSwint} alt="" />
              </MobileImgs>
              <ContentSection>{children}</ContentSection>
              <UserData>
                <p>
                  <span>Empresa:</span> {companyName}
                </p>
                <p>
                  <span>Usuário:</span> {userName}
                </p>
                <button onClick={handleSignOut} type="button">
                  <FaPowerOff size={16} />
                  Sair
                </button>
              </UserData>
              <Version>
                <p>Versão: {packageJson.version}</p>
                <p>Versão API: {apiVersion}</p>
              </Version>
            </>
          )}
        </>
      ) : (
        <>
          <ContentImgs>
            <img src={logo} alt="" />
            <img src={logoSwint} alt="" />
          </ContentImgs>

          <Header>
            {currentValidation !== '' && (
              <h1>Identificação por {currentValidation}</h1>
            )}
            {currentStep !== 1 && <Steps />}
          </Header>
          <ContentSection>{children}</ContentSection>
          <UserData>
            <p>
              <span>Empresa:</span> {companyName}
            </p>
            <p>
              <span>Usuário:</span> {userName}
            </p>
            <button onClick={handleSignOut} type="button">
              <FaPowerOff size={16} />
              Sair
            </button>
          </UserData>
          <Version>
            <p>Versão: {packageJson.version}</p>
            <p>Versão API: {apiVersion}</p>
          </Version>
        </>
      )}
    </Container>
  );
};

export default BodyStep;
