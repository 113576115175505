import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

interface ResultContentProps {
  mode: string;
}

export const ProfileContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    width: 300px;
    height: 200px;
    object-fit: contain;
    border: 1px solid #000;
    border-radius: 4px;
  }

  @media (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    img {
      width: 100%;
      height: 200px;
      border: 0;
      border-radius: 4px;
    }
  }
`;

export const ResultContent = styled.div<ResultContentProps>`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 0;
  border-radius: 4px;

  ${props =>
    props.mode === 'success' &&
    css`
      background: ${colors.aux2};
    `};
  ${props =>
    props.mode === 'error' &&
    css`
      background: #ed4c4c;
    `};
  ${props =>
    props.mode === 'warn' &&
    css`
      background: rgba(255, 200, 3, 1);
    `};

  p {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    padding: 1rem;
    border-radius: 4px;
    color: #fff;

    svg {
      margin-right: 1rem;
    }
  }
`;

export const ProfileData = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 2rem;

  @media (max-width: 600px) {
    padding-left: 0;
    margin-bottom: 2rem;
    align-items: center;
    justify-content: center;
  }

  p {
    margin-bottom: 0.25rem;
  }

  span {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  div:last-child {
    margin-top: 0;

    @media (max-width: 600px) {
      place-content: center;
    }
  }
`;

export const MessageError = styled.span`
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  font-weight: 600;
`;

export const BadgeResult = styled.span`
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;
  border-radius: 4px;
  background: red;
  padding: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
`;
