import { useCallback, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Webcam from 'react-webcam';
import maskCNH from '../../assets/cnhCrop.svg';
import maskFace from '../../assets/maskCam.png';
import { IValidationResponse, useSteps } from '../../contexts/stepContext';
import api from '../../services/api';
import { Title } from '../../styles/global';
import { Content, GoBack } from '../BodyStep/styles';
import Button from '../Button';
import Dropzone from '../Dropzone';
import Loading from '../Loading';
import { Actions, CamSection, Crop, Image } from './styles';

const Camera: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const webcamRef: any = useRef(null);

  const {
    birthday,
    name,
    documentNum,
    documentType,
    zipCode,
    phoneNumber,
    email,
    selfie,
    currentValidation,
    actionFromImage,
    selectedServices,
    handleSetImage,
    setResult,
    setMessageError,
    setActionFromImage,
    setServiceError,
    handleChangeCurrentStep,
    handleReturnCurrentStep,
  } = useSteps();

  const videoConstraints = {
    facingMode: 'user',
  };

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    handleSetImage(imageSrc);
  };

  const handleDeleteImage = useCallback(() => {
    handleSetImage('');
  }, [handleSetImage]);

  const handleGoBack = useCallback(() => {
    handleReturnCurrentStep();
    handleSetImage('');
  }, [handleReturnCurrentStep, handleSetImage]);

  const handleSubmit = async () => {
    setLoading(true);

    const { data } = (await api
      .post('validationrequest', {
        document: documentNum,
        selfie,
        birth_date: birthday,
        name,
        document_type: documentType,
        zip_code: zipCode || undefined,
        phone_number: phoneNumber || undefined,
        email: email || undefined,
        imageJwt: '',
        api_key: process.env.REACT_APP_API_KEY_UNICO,
        selected_services: selectedServices.map(s => s.cos_ser_id),
      })
      .then(response => response)) as { data: IValidationResponse };

    setLoading(false);
    if (data.result) setResult(data.result);
    if (data.message) setMessageError(data.message);
    if (data.service) setServiceError(data.service);

    handleChangeCurrentStep();
  };

  return (
    <>
      <Content>
        <GoBack onClick={handleGoBack}>
          <FaArrowLeft size={16} />
          voltar
        </GoBack>
        <Title>Foto</Title>
        <CamSection photo={!!selfie}>
          {selfie ? (
            <Image id="photo" src={selfie} alt="" />
          ) : (
            <>
              {actionFromImage === 'SELFIE' && (
                <>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotQuality={1}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                  />
                  <Crop>
                    {currentValidation === 'CNH' ? (
                      <img src={maskCNH} alt="" height="auto" width="200px" />
                    ) : (
                      <img src={maskFace} alt="" height="70%" />
                    )}
                  </Crop>
                </>
              )}
              {actionFromImage === 'UPLOAD' && <Dropzone />}
            </>
          )}
        </CamSection>
        <Actions mobile={isMobile}>
          {selfie ? (
            <>
              <Button title="LIMPAR" onClick={handleDeleteImage} />
              <Button
                title="PRÓXIMO"
                onClick={handleSubmit}
                icon={FaArrowRight}
              />
            </>
          ) : (
            <>
              {currentValidation === 'CNH' && actionFromImage === 'SELFIE' && (
                <Button
                  title="UPLOAD DE IMAGEM"
                  onClick={() => setActionFromImage('UPLOAD')}
                />
              )}
              {actionFromImage === 'UPLOAD' && (
                <Button
                  title="CAMERA"
                  onClick={() => setActionFromImage('SELFIE')}
                />
              )}
              {actionFromImage === 'SELFIE' && (
                <Button title="TIRAR FOTO" onClick={capture} />
              )}
            </>
          )}
        </Actions>
      </Content>

      {loading && <Loading />}
    </>
  );
};

export default Camera;
