import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useSteps } from '../../contexts/stepContext';
import { Title } from '../../styles/global';
import { Content, GoBack } from '../BodyStep/styles';
import Button from '../Button';
import InstructionItem from '../InstructionItem';
import {
  BulbIcon,
  ContainerInstructions,
  GlassIcon,
  UserPhotoIcon,
  RotatePhoneIcon,
  RegSquare,
  Focus,
} from './styles';

const Instructions: React.FC = () => {
  const {
    currentValidation,
    handleChangeCurrentStep,
    handleReturnCurrentStep,
  } = useSteps();
  return (
    <Content>
      <GoBack onClick={handleReturnCurrentStep}>
        <FaArrowLeft size={16} />
        voltar
      </GoBack>
      <Title>Instruções</Title>
      {currentValidation === 'Foto' ? (
        <ContainerInstructions>
          <InstructionItem text="Tirar uma selfie com o rosto frontal e visível">
            <UserPhotoIcon />
          </InstructionItem>
          <InstructionItem text="Busque uma boa iluminação">
            <BulbIcon />
          </InstructionItem>
          <InstructionItem text="Remova adereços como óculos e chapeu ">
            <GlassIcon />
          </InstructionItem>
          <InstructionItem text="Evite rotacionar a cãmera do dispositivo ">
            <RotatePhoneIcon />
          </InstructionItem>
        </ContainerInstructions>
      ) : (
        <ContainerInstructions>
          <InstructionItem text="Apoie o documeno em uma superfice reta">
            <RegSquare />
          </InstructionItem>
          <InstructionItem text="Busque uma boa iluminação">
            <BulbIcon />
          </InstructionItem>
          <InstructionItem text="Ajuste o foco para garantir a qualidade ">
            <Focus />
          </InstructionItem>
          <InstructionItem text="Evite rotacionar a cãmera do dispositivo ">
            <RotatePhoneIcon />
          </InstructionItem>
        </ContainerInstructions>
      )}

      <Button
        title="Próximo"
        onClick={handleChangeCurrentStep}
        icon={FaArrowRight}
      />
    </Content>
  );
};

export default Instructions;
