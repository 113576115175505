import React, { forwardRef, InputHTMLAttributes, LegacyRef } from 'react';
import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type: string;
  label?: string;
  width?: string;
}

const Input = (
  { type, width = '100%', label, ...rest }: InputProps,
  ref: LegacyRef<HTMLInputElement>,
) => {
  return (
    <Container {...rest} width={width}>
      {label && <label>{label}</label>}
      <input type={type} ref={ref} {...rest} />
    </Container>
  );
};

export default forwardRef(Input);
