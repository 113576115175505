import React, { useCallback, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useAuth } from '../../contexts/authContext';
import { useSteps } from '../../contexts/stepContext';
import { getParamQuery } from '../../services/optIn';
import { Title } from '../../styles/global';
import { AlertBox } from '../AlertBox';
import { Content } from '../BodyStep/styles';
import Button from '../Button';
import {
  BadgeResult,
  MessageError,
  ProfileContent,
  ProfileData,
  ResultContent,
} from './styels';

const Result: React.FC = () => {
  const [optinText, setOptinText] = useState<string>('');

  const {
    selfie,
    name,
    documentNum,
    clearData,
    result,
    messageError,
    setMessageError,
    handleReturnCurrentStep,
    serviceError,
  } = useSteps();
  const { accessDash, signOut } = useAuth();

  const handleTryAgain = () => {
    handleReturnCurrentStep();
    setMessageError('');
  };
  const returnToPlatform = useCallback(() => {
    signOut();
    window.location.href =
      `${process.env.REACT_APP_URL}/operacional/historicocpf` ||
      'https://swint.imobpower.com/operacional/historicocpf';
  }, [signOut]);

  useEffect(() => {
    getParamQuery().then(response => setOptinText(response.param.par_valor));
  }, []);
  return (
    <Content>
      {serviceError && <BadgeResult>{serviceError}</BadgeResult>}

      <Title>Resultado</Title>
      <ProfileContent>
        {!isMobileOnly && <img src={selfie} alt="" />}
        <ProfileData>
          {name && (
            <>
              <p>Nome Completo</p>
              <span>{name}</span>{' '}
            </>
          )}
          <p>Documento</p>
          <span>{documentNum}</span>
          <ResultContent mode={result}>
            {result === 'success' && (
              <p>
                <FaCheck size={24} color="#fff" />
                RECOMENDADO
              </p>
            )}
            {result === 'error' && (
              <p>
                <FaTimes size={24} color="#fff" />
                NÃO RECOMENDADO
              </p>
            )}
            {result === 'warn' && (
              <p>
                <FaTimes size={24} color="#fff" />
                FALHA
              </p>
            )}
          </ResultContent>
        </ProfileData>
      </ProfileContent>
      <MessageError>{result === 'warn' && messageError}</MessageError>
      <AlertBox message={optinText} removeAnimation />
      {accessDash ? (
        <Button
          title="Voltar a plataforma SGRLOC"
          onClick={returnToPlatform}
          style={{ marginTop: 20 }}
        />
      ) : (
        <Button
          title={result === 'warn' ? 'Tirar nova foto' : 'Voltar ao Início'}
          onClick={result === 'warn' ? handleTryAgain : clearData}
          style={{ marginTop: 20 }}
        />
      )}
    </Content>
  );
};

export default Result;
