import { useCallback, useEffect, useMemo, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import {
  CallbackCamera,
  SelfieCameraTypes,
  SuccessPictureResponse,
  UnicoCheckBuilder,
  UnicoThemeBuilder,
} from 'unico-webframe';
import { IValidationResponse, useSteps } from '../../contexts/stepContext';
import api from '../../services/api';
import { Title } from '../../styles/global';
import { Content, GoBack } from '../BodyStep/styles';
import Button from '../Button';
import { CamSection } from '../Camera/styles';
import Loading from '../Loading';
import { ContainerImg } from './styles';

type OpenCameraState = {
  openCamera: (callback: CallbackCamera) => void;
  cameraName: string;
  isCameraReady: boolean;
  isUnicoCamera: boolean;
};

const FaceTecCamera = () => {
  const [preparedCamera, setPreparedCamera] = useState<OpenCameraState>(
    {} as OpenCameraState,
  );
  const [showBoxCamera, setShowBoxCamera] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageJwt, setImageJwt] = useState('');
  const [activeCam, setActiveCam] = useState(false);

  const urlPathModels = `${window.location.protocol}//${window.location.host}/models`;

  const {
    birthday,
    name,
    documentNum,
    documentType,
    zipCode,
    phoneNumber,
    email,
    selfie,
    handleSetImage,
    setResult,
    setMessageError,
    setServiceError,
    handleChangeCurrentStep,
    handleReturnCurrentStep,
    selectedServices,
  } = useSteps();

  const unicoTheme = new UnicoThemeBuilder()
    .setColorSilhouetteSuccess('#ffcd42')
    .setColorSilhouetteError('#D50000')
    .setColorSilhouetteNeutral('#fcfcfc')
    .setBackgroundColor('#f8f8f8')
    .setColorText('#2d2e36')
    .setBackgroundColorComponents('#ffbc03')
    .setColorTextComponents('#fff')
    .setBackgroundColorButtons('#ffbc03')
    .setColorTextButtons('#fff')
    .setBackgroundColorBoxMessage('#fff')
    .setColorTextBoxMessage('#2d2e36')
    .setHtmlPopupLoading(
      `<div style="position: absolute; top: 45%; right: 50%; transform: translate(50%, -50%); z-index: 10; text-align: center; background: '#ffbc03'">Carregando...</div>`,
    )
    .build();
  const unicoCamera = new UnicoCheckBuilder()
    .setTheme(unicoTheme)
    .setModelsPath(urlPathModels)
    .setResourceDirectory('/resources')
    .build();

  const initCapture = useCallback(async () => {
    setShowBoxCamera(false);

    const { open } = await unicoCamera.prepareSelfieCamera(
      process.env.REACT_APP_URL_SERVICE_UNICO || '/servicesDev.json',
      SelfieCameraTypes.NORMAL,
    );

    setPreparedCamera({
      openCamera: open,
      isCameraReady: true,
      cameraName: 'Facetec Liveness',
      isUnicoCamera: false,
    });
    setShowBoxCamera(false);
  }, [unicoCamera]);

  const resetComponentStates = () => {
    setPreparedCamera({} as OpenCameraState);
    setShowBoxCamera(false);
  };

  const sdkCallbacks: CallbackCamera = useMemo(
    () => ({
      on: {
        async success(obj: SuccessPictureResponse) {
          resetComponentStates();
          handleSetImage(`data:image/jpeg;base64,${obj.base64}`);
          setImageJwt(obj.encrypted);
          setActiveCam(false);
        },
        error() {
          resetComponentStates();
          setActiveCam(false);
        },
        support() {
          initCapture();
          setActiveCam(false);
        },
      },
    }),
    [initCapture, handleSetImage],
  );

  const handleSubmit = async () => {
    setLoading(true);

    const { data } = (await api
      .post('validationrequest', {
        document: documentNum,
        selfie,
        birth_date: birthday,
        name,
        document_type: documentType,
        zip_code: zipCode || undefined,
        phone_number: phoneNumber || undefined,
        email: email || undefined,
        imageJwt,
        api_key: process.env.REACT_APP_API_KEY_UNICO,
        selected_services: selectedServices.map(s => s.cos_ser_id),
      })
      .then(response => response)) as { data: IValidationResponse };

    setLoading(false);
    if (data.result) setResult(data.result);
    if (data.message) setMessageError(data.message);
    if (data.service) setServiceError(data.service);

    handleChangeCurrentStep();
  };

  const handleGoBack = useCallback(() => {
    handleReturnCurrentStep();
    handleSetImage('');
    setImageJwt('');
  }, [handleReturnCurrentStep, handleSetImage]);

  useEffect(() => {
    if (preparedCamera.isUnicoCamera === false) {
      setShowBoxCamera(true);
      preparedCamera.openCamera(sdkCallbacks);
    }
  }, [preparedCamera, sdkCallbacks]);

  return (
    <Content>
      <GoBack onClick={handleGoBack}>
        <FaArrowLeft size={16} />
        voltar
      </GoBack>
      <Title>{selfie ? 'Foto capturada' : 'Foto'}</Title>
      <CamSection photo={!!selfie}>
        {selfie ? (
          <ContainerImg>
            <img
              src={selfie}
              width="250px"
              height="300px"
              alt="Foto Capturada"
              style={{ objectFit: 'contain' }}
            />
            <div>
              <Button
                style={{
                  background: 'rgba(255, 188, 3, 1)',
                  padding: '0.5rem 2rem',
                  borderRadius: '2rem',
                  cursor: 'pointer',
                }}
                title="LIMPAR"
                onClick={() => {
                  resetComponentStates();
                  handleSetImage('');
                  setImageJwt('');
                }}
              />
              <Button
                style={{
                  background: 'rgba(255, 188, 3, 1)',
                  padding: '0.5rem 2rem',
                  borderRadius: '2rem',
                  cursor: 'pointer',
                }}
                title="PRÓXIMO"
                onClick={handleSubmit}
              />
            </div>
          </ContainerImg>
        ) : (
          <>
            {!showBoxCamera ? (
              <Button
                type="button"
                onClick={() => {
                  initCapture();
                  setActiveCam(true);
                }}
                disabled={activeCam}
                isLoading={activeCam}
                textLoading="Carregando a captura"
                title="INICIAR CÂMERA"
                style={{
                  background: activeCam
                    ? 'rgba(204, 150, 0)'
                    : 'rgba(255, 188, 3, 1)',
                  padding: '0.5rem 2rem',
                  borderRadius: '2rem',
                  cursor: activeCam ? 'not-allowed' : 'pointer',
                }}
              />
            ) : (
              <Loading />
            )}
          </>
        )}
      </CamSection>
      {loading && <Loading />}
    </Content>
  );
};

export { FaceTecCamera };
