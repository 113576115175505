import React from 'react';
import BodyStep from '../../components/BodyStep';
import OptIn from '../../components/OptIn';
import { useAuth } from '../../contexts/authContext';
import { useSteps } from '../../contexts/stepContext';

const Balcao: React.FC = () => {
  const { changeStep } = useSteps();
  const { companyName, optin } = useAuth();
  return (
    <>
      {optin === 0 && <OptIn />}
      {companyName ? <BodyStep>{changeStep()}</BodyStep> : <>{changeStep()}</>}
    </>
  );
};

export default Balcao;
