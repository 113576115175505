import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

interface CamProps {
  mobile: boolean;
}

interface CamSectionProps {
  photo: boolean;
}

export const CamSection = styled.section<CamSectionProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 320px;
  width: 400px;
  position: relative;

  @media (max-width: 600px) {
    width: 100%;
    height: ${p => (p.photo ? 'fit-content' : '280px')};
  }

  video {
    min-height: 300px;
    width: auto;
    background: #ccc;
  }

  button {
    border: 0;
    background: ${colors.aux2};
    padding: 0.5rem;
    color: #fff;
    width: fit-content;
    border-radius: 8px;
    align-self: center;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
    transition: filter 0.3s;

    &:hover {
      filter: brightness(0.9);
      color: #fff;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Crop = styled.div`
  margin-top: 0 !important;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  position: absolute;
  top: 0;
  justify-content: center;
`;

export const Actions = styled.div<CamProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  button {
    margin: 0 0.5rem;

    ${props =>
      props.mobile &&
      css`
        margin: 0.5rem;
      `}
  }
`;

export const UpFile = styled.label`
  cursor: pointer;
  position: relative;
  margin-left: 0.5rem;

  border: none;
  border-radius: 2rem;
  font-weight: 900;
  color: #fff;
  background: ${colors.aux1};
  font-size: 1rem;
  line-height: 1.5rem;
  height: 50px;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 0.2s;

  &:hover {
    filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.5));
  }

  input {
    display: none;
  }

  /* &::after {
    content: 'Enviar arquivo';

    position: absolute;
    top: -30px;
    right: -40px;
    width: 90px;

    pointer-events: none;
    opacity: 0;
    -webkit-transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -ms-transition: opacity 0.15s ease-in-out;
    -o-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;

    display: block;
    font-size: 14px;
    font-weight: bold;
    background: #000;
    color: #fff;
    padding: 4px;
    border-radius: 4px;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.4);
  } */

  /* &:hover::after {
    opacity: 1;
  } */
`;
