import { cpf } from 'cpf-cnpj-validator';
import { createMask } from 'imask';
import React, { ChangeEvent, useCallback } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { useSteps } from '../../contexts/stepContext';
import { Title } from '../../styles/global';
import { errorToast } from '../../utils/toast';
import { Content, GoBack } from '../BodyStep/styles';
import Button from '../Button';
import DropDown from '../DropDown';
import Input from '../Input';
import { FormContent } from './styles';

const maskCpf = createMask({
  mask: '000.000.000-00',
});

const maskBirth = createMask({
  mask: '00/00/0000',
});

const Identification: React.FC = () => {
  const {
    handleChangeCurrentStep,
    documentNum,
    name,
    birthday,
    documentType,
    handleSetDocument,
    handleSetName,
    handleSetBirthday,
    handleReturnCurrentStep,
    setDocumentType,
    documents,
  } = useSteps();

  const { handleSubmit } = useForm();

  const handleValidate = useCallback(() => {
    if (name === '') {
      errorToast({ message: 'Nome é obrigatório!' });
      return;
    }

    if (birthday === '') {
      errorToast({ message: 'Data de nascimento é obrigatório!' });
      return;
    }

    if (documentType === 1 || documentType === 0) {
      if (!cpf.isValid(documentNum)) {
        errorToast({ message: 'Documento inválido' });
        return;
      }
      handleChangeCurrentStep();
      return;
    }
    handleChangeCurrentStep();
  }, [documentNum, documentType, handleChangeCurrentStep, name, birthday]);

  const setCpf = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = maskCpf.resolve(e.target.value);
    handleSetDocument(e.target.value);
  };

  const setBirth = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = maskBirth.resolve(e.target.value);
    handleSetBirthday(e.target.value);
  };

  return (
    <Content>
      <GoBack onClick={handleReturnCurrentStep}>
        <FaArrowLeft size={16} />
        voltar
      </GoBack>
      <Title>Dados Pessoais</Title>
      <FormContent onSubmit={handleSubmit(handleValidate)}>
        <Input
          type="text"
          label="Nome Completo"
          onChange={e => handleSetName(e.target.value)}
          value={name}
          width="70%"
          autoFocus
        />
        <Input
          type="tel"
          label="Data de nascimento"
          onChange={e => setBirth(e)}
          value={birthday}
          width="40%"
        />

        <DropDown
          label="Tipo do documento"
          options={documents}
          onChange={e => {
            setDocumentType(Number(e.target.value));
          }}
          width="40%"
        />
        <Input
          type="tel"
          label="Número"
          onChange={e =>
            documentType === 1 ? setCpf(e) : handleSetDocument(e.target.value)
          }
          value={documentNum}
          disabled={documentType === 0}
          width="40%"
        />
        <Button title="Próximo" type="submit" icon={FaArrowRight} />
      </FormContent>
    </Content>
  );
};

export default Identification;
