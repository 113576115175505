import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiUpload } from 'react-icons/fi';
import { useSteps } from '../../contexts/stepContext';
import { errorToast } from '../../utils/toast';
import { Container } from './styles';

const Dropzone: React.FC = () => {
  const { ImageToBase64, selfie } = useSteps();

  const onDrop = useCallback(
    async acceptedFiles => {
      const file = acceptedFiles[0];
      if (file.size > 1024000) {
        errorToast({
          message: 'Tamanho máximo da imagem deve ser de 1Mb',
          options: {
            autoClose: false,
          },
        });
        return;
      }
      ImageToBase64(file);
    },
    [ImageToBase64],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/png, image/jpeg',
  });

  return (
    <Container {...getRootProps()}>
      <section>
        <input {...getInputProps()} accept="image/png, image/jpeg" />
        {selfie ? (
          <img src={selfie} alt="Imagem do documento" />
        ) : (
          <p>
            <FiUpload />
            Selecione seu documento
          </p>
        )}
      </section>
    </Container>
  );
};

export default Dropzone;
