import { motion } from 'framer-motion';
import React from 'react';
import { useAuth } from '../../contexts/authContext';
import { useSteps } from '../../contexts/stepContext';
import {
  ArrowRight,
  CameraIcon,
  CheckIcon,
  Container,
  ErrorIcon,
  InstructionsIcon,
  AdditionalInfoIcon,
  StepItem,
  UserIcon,
} from './styles';

const Steps = (): React.ReactElement => {
  const { currentStep } = useSteps();
  const { additionalFields } = useAuth();
  const steps = additionalFields ? [1, 2, 3, 4, 5] : [1, 2, 3, 4];

  const multIcons = (icon: number) => {
    if (additionalFields) {
      switch (icon) {
        case 1:
          return <UserIcon />;
        case 2:
          return <AdditionalInfoIcon />;
        case 3:
          return <InstructionsIcon />;
        case 4:
          return <CameraIcon />;
        case 5:
          return <CheckIcon />;
        default:
          return <ErrorIcon />;
      }
    } else {
      switch (icon) {
        case 1:
          return <UserIcon />;
        case 2:
          return <InstructionsIcon />;
        case 3:
          return <CameraIcon />;
        case 4:
          return <CheckIcon />;
        default:
          return <ErrorIcon />;
      }
    }
  };

  return (
    <Container>
      <ul>
        <>
          {steps.map((forms: number, key: number) => {
            return (
              <StepItem
                as={motion.li}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 0.5 }}
                // eslint-disable-next-line react/no-array-index-key
                key={key}
                selected={key < currentStep - 1}
              >
                <div>{multIcons(forms)}</div>
                {key !== steps.length - 1 && <ArrowRight />}
              </StepItem>
            );
          })}
        </>
      </ul>
    </Container>
  );
};

export default Steps;
