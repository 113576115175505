import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import Webcam from 'react-webcam';
import maskCNH from '../assets/cnhCrop.svg';
import maskFace from '../assets/maskCam.png';
import Button from '../components/Button';
import Camera from '../components/Camera';
import { Crop } from '../components/Camera/styles';
import Choose from '../components/Choose';
import Dropzone from '../components/Dropzone';
import Identification from '../components/Identification';
import Instructions from '../components/Instructions/index';
import Loading from '../components/Loading';
import Result from '../components/Result';
import AdditionalFields from '../components/AdditionalFields';
import api from '../services/api';
import { getDocuments, IDocumentsSelect } from '../services/documents';
import { MobileCam } from '../styles/global';
import { useAuth } from './authContext';
import { FaceTecCamera } from '../components/FaceTecCamera';
import ServicesSelection from '../components/ServicesSelection';

interface Service {
  cos_ser_id: number;
  ser_name: string;
  ser_description: string;
  ser_pubsub: string;
  ser_additional_fields: boolean;
}
interface StepContextData {
  documentNum: string;
  name: string;
  birthday: string;
  zipCode: string;
  phoneNumber: string;
  email: string;
  selfie: string;
  currentStep: number;
  currentValidation: string;
  result: string;
  loading: boolean;
  documentType: number;
  messageError: string;
  actionFromImage: string;
  serviceError: string;
  documents: IDocumentsSelect[];
  selectedServices: Service[];
  handleSetDocument: (cpfParam: string) => void;
  handleSetName: (nameParam: string) => void;
  handleSetBirthday: (birthParam: string) => void;
  handleSetZipCode: (zipCodeParam: string) => void;
  handleSetPhoneNumber: (phoneNumberParam: string) => void;
  handleSetEmail: (emailParam: string) => void;
  handleSetImage: (imageParam: string) => void;
  setSelectedServices: React.Dispatch<React.SetStateAction<Service[]>>;
  changeStep: () => React.ReactElement;
  setResult: React.Dispatch<React.SetStateAction<string>>;
  handleSetLoading: (loadingParam: boolean) => void;
  handleChangeCurrentStep: () => void;
  handleReturnCurrentStep: () => void;
  handleChangeCurrentValidation: (validation: string) => void;
  clearData: () => void;
  setDocumentType: React.Dispatch<React.SetStateAction<number>>;
  setMessageError: (value: string) => void;
  ImageToBase64: (file: File) => void;
  setActionFromImage: (value: string) => void;
  setServiceError: (value: string) => void;
}
export interface IValidationResponse {
  message?: string;
  result?: string;
  service?: string;
}

const StepContext = createContext<StepContextData>({} as StepContextData);

export function StepProvider({ children }: { children: React.ReactNode }) {
  const history = useHistory();
  const { additionalFields, canSelectServices } = useAuth();

  const webcamRef = React.useRef<Webcam>();

  const [currentStep, setCurrentStep] = useState(1);
  const [name, setName] = useState('');
  const [documentNum, setDocumentNum] = useState('');
  const [selfie, setImage] = useState('');
  const [currentValidation, setCurrentValidation] = useState('');
  const [birthday, setBirthday] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [result, setResult] = useState('');
  const [messageError, setMessageError] = useState('');
  const [documentType, setDocumentType] = useState(0);
  const [loading, setLoading] = useState(true);
  const [actionFromImage, setActionFromImage] = useState('SELFIE');
  const [serviceError, setServiceError] = useState('');
  const [documents, setDocuments] = useState<IDocumentsSelect[]>([]);
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);

  const lifeProof = localStorage.getItem('@SWINTbalcao:lifeProof');

  const handleChangeCurrentStep = useCallback(() => {
    setCurrentStep(prev => prev + 1);
  }, []);

  const handleReturnCurrentStep = useCallback(() => {
    setCurrentStep(prev => prev - 1);
  }, []);

  const changeStep = useCallback(() => {
    let addFields = false;

    if (selectedServices.length === 0) {
      addFields = additionalFields;
    } else {
      addFields = selectedServices.some(s => s.ser_additional_fields === true);
    }

    if (!addFields && !canSelectServices) {
      switch (currentStep) {
        case 1:
          return <Choose />;
        case 2:
          return <Identification />;
        case 3:
          return <Instructions />;
        case 4:
          return lifeProof === 'false' ||
            (lifeProof === 'true' && currentValidation !== 'Foto') ? (
            <Camera />
          ) : (
            <FaceTecCamera />
          );
        case 5:
          return <Result />;
        default:
          return <></>;
      }
    }

    if (addFields && !canSelectServices) {
      switch (currentStep) {
        case 1:
          return <Choose />;
        case 2:
          return <Identification />;
        case 3:
          return <AdditionalFields />;
        case 4:
          return <Instructions />;
        case 5:
          return lifeProof === 'false' ||
            (lifeProof === 'true' && currentValidation !== 'Foto') ? (
            <Camera />
          ) : (
            <FaceTecCamera />
          );
        case 6:
          return <Result />;
        default:
          return <></>;
      }
    }

    if (!addFields && canSelectServices) {
      switch (currentStep) {
        case 1:
          return <ServicesSelection />;
        case 2:
          return <Choose />;
        case 3:
          return <Identification />;
        case 4:
          return <Instructions />;
        case 5:
          return lifeProof === 'false' ||
            (lifeProof === 'true' && currentValidation !== 'Foto') ? (
            <Camera />
          ) : (
            <FaceTecCamera />
          );
        case 6:
          return <Result />;
        default:
          return <></>;
      }
    }

    if (addFields && canSelectServices) {
      switch (currentStep) {
        case 1:
          return <ServicesSelection />;
        case 2:
          return <Choose />;
        case 3:
          return <Identification />;
        case 4:
          return <AdditionalFields />;
        case 5:
          return <Instructions />;
        case 6:
          return lifeProof === 'false' ||
            (lifeProof === 'true' && currentValidation !== 'Foto') ? (
            <Camera />
          ) : (
            <FaceTecCamera />
          );
        case 7:
          return <Result />;
        default:
          return <></>;
      }
    }

    return <></>;
  }, [
    selectedServices,
    currentStep,
    canSelectServices,
    additionalFields,
    lifeProof,
    currentValidation,
  ]);

  const capture = () => {
    const imageSrc = webcamRef.current?.getScreenshot() || '';
    setImage(imageSrc);
  };

  const clearData = useCallback(() => {
    setDocumentNum('');
    setImage('');
    setName('');
    setBirthday('');
    setZipCode('');
    setPhoneNumber('');
    setEmail('');
    setCurrentStep(1);
    setCurrentValidation('');
    setMessageError('');
    setResult('');
    setServiceError('');
  }, []);

  const ImageToBase64 = useCallback((file: File): void => {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      setImage(reader.result as string);
    };
    reader.onerror = () => {
      return false;
    };
  }, []);

  useEffect(() => {
    const onLocationFailed = () => {
      setLoading(false);
      history.push('/geolocationDenied');
    };

    const getUserLocation = () => {
      setLoading(true);
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude, longitude } = position.coords;

          localStorage.setItem('@SWINTbalcao:latitude', String(latitude));
          localStorage.setItem('@SWINTbalcao:longitude', String(longitude));
          api.defaults.headers.common.lat = latitude;
          api.defaults.headers.common.lng = longitude;
          api.defaults.headers.common.cmpid = '';
          api.defaults.headers.common.plcid = '';
          setLoading(false);
        }, onLocationFailed);
      } else {
        onLocationFailed();
      }
    };

    const getOptions = () => {
      getDocuments().then(response => {
        if (response) setDocuments(response);
      });
    };

    getUserLocation();
    getOptions();
  }, []);

  useEffect(() => {
    if (currentStep === 1) {
      clearData();
    }
  }, [currentStep, clearData]);

  const value = useMemo(
    () => ({
      loading,
      documentNum,
      name,
      birthday,
      zipCode,
      phoneNumber,
      email,
      selfie,
      currentStep,
      result,
      documentType,
      selectedServices,
      handleSetDocument: setDocumentNum,
      handleSetName: setName,
      handleSetBirthday: setBirthday,
      handleSetZipCode: setZipCode,
      handleSetPhoneNumber: setPhoneNumber,
      handleSetEmail: setEmail,
      handleSetImage: setImage,
      handleSetLoading: setLoading,
      handleChangeCurrentValidation: setCurrentValidation,
      setResult,
      setDocumentType,
      setMessageError,
      setActionFromImage,
      setServiceError,
      setSelectedServices,
      handleChangeCurrentStep,
      handleReturnCurrentStep,
      changeStep,
      clearData,
      ImageToBase64,
      currentValidation,
      messageError,
      actionFromImage,
      serviceError,
      documents,
    }),
    [
      loading,
      documentNum,
      name,
      birthday,
      zipCode,
      phoneNumber,
      email,
      selfie,
      currentStep,
      result,
      documentType,
      selectedServices,
      handleChangeCurrentStep,
      handleReturnCurrentStep,
      changeStep,
      clearData,
      ImageToBase64,
      currentValidation,
      messageError,
      actionFromImage,
      serviceError,
      documents,
    ],
  );

  return (
    <StepContext.Provider value={value}>
      {isMobile &&
      (additionalFields ? currentStep === 5 : currentStep === 4) &&
      !selfie &&
      (lifeProof === 'false' ||
        (lifeProof === 'true' && currentValidation !== 'Foto')) ? (
        <MobileCam>
          <section>
            {actionFromImage === 'SELFIE' && (
              <>
                <Webcam
                  audio={false}
                  ref={webcamRef as any}
                  screenshotQuality={1}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                    facingMode:
                      currentValidation === 'Foto' ? 'user' : 'environment',
                  }}
                  style={{
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                    background: 'rgba(0, 0, 0, 0.85)',
                  }}
                />
                <Crop>
                  {currentValidation === 'Foto' ? (
                    <img src={maskFace} alt="" height="80%" />
                  ) : (
                    <img src={maskCNH} alt="" height="98%" />
                  )}
                </Crop>
              </>
            )}
            {actionFromImage === 'UPLOAD' && <Dropzone />}
          </section>
          {currentValidation === 'CNH' && actionFromImage === 'SELFIE' && (
            <Button
              title="UPLOAD DE IMAGEM"
              onClick={() => setActionFromImage('UPLOAD')}
            />
          )}
          {actionFromImage === 'UPLOAD' && (
            <Button
              title="CAMERA"
              onClick={() => setActionFromImage('SELFIE')}
            />
          )}
          {actionFromImage === 'SELFIE' && (
            <Button
              title="TIRAR FOTO"
              onClick={capture}
              style={{ alignSelf: 'center' }}
            />
          )}
        </MobileCam>
      ) : (
        <>{children}</>
      )}
      {loading && <Loading />}
    </StepContext.Provider>
  );
}

export function useSteps(): StepContextData {
  const context = useContext(StepContext);

  if (!context) {
    throw new Error(
      "Custom hook 'useSteps' must be used within an 'StepProvider'",
    );
  }

  return context;
}
