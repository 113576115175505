import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaRegEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logoSGR from '../../assets/SGRLock.png';
import logoSwint from '../../assets/swintLogo.svg';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Loading from '../../components/Loading';

import api from '../../services/api';
import { Container, Content } from './styles';

const ForgotPassoword: React.FC = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [send, setSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    const response = await api.post('/requestChangePassword', {
      user: email,
    });
    if (response.data.result === 'error') {
      setErrorMessage(response.data.message);
      setLoading(false);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setSend(true);
    }
    setIsLoading(false);

    return { error: false, message: 'success' };
  };

  useEffect(() => {
    setValue('email', email);
  }, [setValue, email]);

  return (
    <Container>
      <img src={logoSGR} alt="Logo SGRLoc" />
      <Content>
        <img src={logoSwint} alt="Logo Swint" />
        <h1>Recuperação de Senha</h1>
        {!send ? (
          <>
            {errorMessage && <p>{errorMessage}</p>}
            <form onSubmit={handleSubmit(onSubmit) as any}>
              <Input
                {...register('email')}
                type="text"
                placeholder="Informe seu e-mail"
                label="E-MAIL"
                onChange={e => setEmail(e.target.value)}
              />
              <Button
                type="submit"
                title="ENVIAR"
                icon={FaRegEnvelope}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </form>
          </>
        ) : (
          <h3>
            Enviamos a solicitação de alteração de senha para seu Gestor. Por
            favor entre em contato com ele para confirmar a alteração.
          </h3>
        )}
        <Link to="/">Voltar ao login</Link>
      </Content>
      {loading && <Loading />}
    </Container>
  );
};

export default ForgotPassoword;
