import { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useSteps } from '../../contexts/stepContext';
import { getCompanyServices } from '../../services/services';
import { Title } from '../../styles/global';
import { errorToast } from '../../utils/toast';
import { Content } from '../BodyStep/styles';
import Button from '../Button';
import Loading from '../Loading';
import ToggleSwitch from '../ToggleSwitch';

interface Service {
  cos_ser_id: number;
  ser_name: string;
  ser_description: string;
  ser_pubsub: string;
  ser_additional_fields: boolean;
}

export default function ServicesSelection() {
  const [isMounted, setIsMounted] = useState(false);
  const { selectedServices, setSelectedServices, handleChangeCurrentStep } =
    useSteps();

  const [services, setServices] = useState<(Service & { selected: boolean })[]>(
    [],
  );

  const toggle = (id: number, value: boolean) => {
    setServices(prev => {
      const copy = [...prev];
      const index = prev.findIndex(s => s.cos_ser_id === id);
      const found = { ...copy[index] };
      found.selected = value;
      copy[index] = found;
      return copy;
    });
  };

  useEffect(() => {
    const getServices = async (signal: AbortSignal) => {
      const data = await getCompanyServices(signal);

      setIsMounted(true);

      if (selectedServices.length === 0) {
        setServices(
          data.map(s => ({
            ...s,
            selected: true,
          })),
        );
        return;
      }

      const selectedServicesIds = selectedServices.map(s => s.cos_ser_id);

      setServices(
        data.map(s => ({
          ...s,
          selected: selectedServicesIds.includes(s.cos_ser_id),
        })),
      );
    };

    const controller = new AbortController();

    if (!isMounted) {
      getServices(controller.signal);
    }

    return () => {
      controller.abort();
    };
  }, [isMounted, selectedServices]);

  const nextStep = () => {
    const localSelectedServices = services.filter(s => s.selected);

    if (localSelectedServices.length === 0) {
      errorToast({ message: 'Selecione pelo menos um serviço' });
      return;
    }

    if (localSelectedServices.length === services.length) {
      setSelectedServices([]);
    }

    if (localSelectedServices.length !== services.length) {
      setSelectedServices(localSelectedServices);
    }

    handleChangeCurrentStep();
  };

  return (
    <Content>
      <Title>Selecionar serviços</Title>
      <div style={{ width: '100%', padding: '1rem 2rem', overflowY: 'auto' }}>
        {!isMounted && <Loading />}
        {services.map(s => (
          <div
            key={s.cos_ser_id}
            style={{
              marginBottom: '1rem',
              height: '1.6rem',
            }}
          >
            <ToggleSwitch
              id={`toggle-${s.cos_ser_id}`}
              checked={s.selected}
              title={s.ser_description}
              onChange={value => toggle(s.cos_ser_id, value)}
            />
          </div>
        ))}
      </div>
      <Button
        title="Próximo"
        type="button"
        onClick={nextStep}
        icon={FaArrowRight}
      />
    </Content>
  );
}
