import React, { useCallback, useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import card from '../../assets/card.png';
import faceDetection from '../../assets/faceDetection.png';
import { useAuth } from '../../contexts/authContext';
import { useSteps } from '../../contexts/stepContext';
import { Title } from '../../styles/global';
import { Content, GoBack } from '../BodyStep/styles';
import { ChooseContainer } from './styles';

const Choose: React.FC = () => {
  const {
    handleChangeCurrentStep,
    handleChangeCurrentValidation,
    handleReturnCurrentStep,
  } = useSteps();

  const { canSelectServices } = useAuth();

  const handleSetCNHValidation = useCallback(() => {
    handleChangeCurrentValidation('CNH');
    handleChangeCurrentStep();
  }, [handleChangeCurrentValidation, handleChangeCurrentStep]);

  const handleSetFotoValidation = useCallback(() => {
    handleChangeCurrentValidation('Foto');
    handleChangeCurrentStep();
  }, [handleChangeCurrentValidation, handleChangeCurrentStep]);

  useEffect(() => {
    handleChangeCurrentValidation('');
  }, [handleChangeCurrentValidation]);

  return (
    <Content>
      {canSelectServices && (
        <GoBack onClick={handleReturnCurrentStep}>
          <FaArrowLeft size={16} />
          voltar
        </GoBack>
      )}
      <Title>Selecionar método de análise</Title>
      <ChooseContainer>
        <button onClick={handleSetCNHValidation} type="button">
          <img src={card} alt="" /> Análise por foto da CNH/Passaporte
        </button>
        <button onClick={handleSetFotoValidation} type="button">
          <img src={faceDetection} alt="" /> Análise por foto da face
        </button>
      </ChooseContainer>
    </Content>
  );
};

export default Choose;
