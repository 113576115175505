import api from './api';

type GetCompanyServicesResponse = {
  cos_ser_id: number;
  ser_name: string;
  ser_description: string;
  ser_pubsub: string;
  ser_additional_fields: boolean;
}[];

export async function getCompanyServices(
  signal: AbortSignal,
): Promise<GetCompanyServicesResponse> {
  const { data } = await api
    .get('/services', { signal })
    .then(response => response)
    .catch(err => err.response);

  return data;
}
