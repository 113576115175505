import React, { SelectHTMLAttributes } from 'react';

import { Container } from './styles';

interface DropDownProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  options: Array<{
    id: number;
    label: string;
  }>;
  width?: string;
}

const DropDown: React.FC<DropDownProps> = ({
  label,
  options,
  width = '100%',
  ...rest
}) => {
  return (
    <Container width={width}>
      <label>{label}</label>
      <select name="" id="" {...rest}>
        <option value="" hidden>
          Selecione um tipo
        </option>
        {options.map(option => {
          return (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          );
        })}
      </select>
    </Container>
  );
};

export default DropDown;
