import styled from 'styled-components';
import colors from '../../styles/colors';

export const ChooseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;

  button {
    background: #fff;
    border: none;
    padding: 1rem;
    border-radius: 4px;
    font-weight: bold;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 350px;
    text-transform: uppercase;

    margin: 1rem;
    transition: background filter 0.3s, color 0.2s;

    &:hover {
      background: ${colors.aux1};
      color: #fff;

      img {
        filter: invert(1);
      }
    }

    img {
      width: 40px;
      height: 40px;
    }
  }
`;
